<script setup lang="ts">

import {defaultAirport, defaultDate, flightSearchValidate, makeSearch} from "~/utils/flight/search-utils";
import type {FormError, FormSubmitEvent} from "#ui/types";
import type {FlightSearchForm} from "~/types/flight/search/FlightSearchForm";
import type {FlightSearchDate} from "~/types/flight/search/FlightSearchDate";
import {getMonthTextDayAndMonth} from "~/utils/calendar/persian-utils";
import type {TripType} from "~/types/flight/search/TripType";
import {MakePassenger, typeByCode} from "~/utils/flight/passenger/MakePassenger";
import type {PassengerRule} from "~/types/flight/passenger/PassengerRule";
import {PassengerTypeCount} from "~/utils/flight/passenger/PassengerTypeCount";
import {useAirportStore} from "~/stores/flight/airport/useAirport";
import {storeToRefs} from "pinia";
import {onErrorForm} from "~/utils/base/common-utils";
import type {CityAirport} from "~/types/flight/city/CityAirport";
import {exchangeIcon, loadingIcon} from "~/utils/icon-utils";
import type {CabinType} from "~/types/flight/search/CabinType";
import type {FlightTypeItem} from "~/types/flight/FlightTypeItem";

const props = defineProps<{
    tripType?: TripType,
    origin?: CityAirport,
    destination?: CityAirport,
    cabinType?: CabinType,
    flightType?: FlightTypeItem
    hasRoute?: boolean,
    noPath?: boolean
}>()

const emit = defineEmits(['handleSearch'])

const airportStore = useAirportStore()
const {data: airports, loading: airportLoading, q} = storeToRefs(airportStore)
const {getAirports} = airportStore

const state = reactive(makeSearch(props.origin ?? defaultAirport(), props.destination ?? defaultAirport(), defaultDate(), props.tripType, props.flightType, props.cabinType ?? null));
const showDatePiker = ref(false)
const showPassengersOptions = ref(false)
const dateInput = ref('')
const passengerCounts = computed(() => PassengerTypeCount(state.passengers));

const passengerText = computed(() => passengerCounts.value.text);

watch(props, () => {
    if (props.tripType) {
        dateInput.value = ''
        Object.assign(state, makeSearch(props.origin ?? defaultAirport(), props.destination ?? defaultAirport(), defaultDate(), props.tripType))
    }
})


const handleDate = (date: FlightSearchDate, close: boolean) => {
    if (close)
        showDatePiker.value = false
    state.date = date;
    dateInput.value = getMonthTextDayAndMonth(date.start_date.persian, date.end_date?.persian)
}


const handlePassenger = (params: { passenger: PassengerRule; action: "add" | "remove" }) => {
    if (params.action === "add") {
        state.passengers.push(MakePassenger(typeByCode(params.passenger.type)));
    } else if (params.action === "remove") {
        const index = state.passengers.findIndex(
            (p) => p.type.code === params.passenger.type
        );
        if (index !== -1) state.passengers.splice(index, 1);
    }


};

const getAirportsList = async (query: string | FocusEvent) => {
    q.value = query instanceof FocusEvent ? '' : query;
    await getAirports()
    return airports.value
}

const handleOut = () => {
    showDatePiker.value = false
    showPassengersOptions.value = false
}

const handleSearch = async (event: FormSubmitEvent<any>) => {
    emit('handleSearch', state)
}

</script>

<template>
    <div class="w-full ">
        <u-form
            :state="state"
            :validate="flightSearchValidate"
            @error="onErrorForm"
            @submit="handleSearch"
            class="w-full grid grid-cols-10 justify-center items-center pt-2 pb-6 gap-2"
        >
            <div v-if="!noPath" class="w-full flex gap-4 col-span-5 relative">
                <u-button
                    :color="hasRoute ? 'white': 'primary'"
                    variant="ghost"
                    size="xs"
                    :icon="exchangeIcon()"
                    square
                    :ui="{base: `${hasRoute ? 'border-white' : 'border-primary-900'} absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white border rounded-full`}"
                />
                <u-form-field
                    name="origin"
                    :ui="{root: hasRoute ? 'w-full bg-transparent border border-white rounded-r-2xl rounded-l-lg' : 'w-full bg-transparent border border-primary-900 rounded-r-2xl rounded-l-lg', error: 'absolute text-xs'}"
                >
                    <u-select-menu
                        v-model="state.origin"
                        :filter-fields="['name_en', 'name_fa', 'iata_code', 'city.name_en', 'city.name_fa']"
                        :items="airports"
                        :loading="airportLoading"
                        :search-input="{placeholder: 'جستجوی مبدا'}"
                        placeholder="مبدا"
                        label-key="city.name_fa"
                        highlight
                        color="gray"
                        size="xl"
                        variant="none"
                        trailing
                        :leading-icon="loadingIcon()"
                        by="iata_code"
                        @focus="getAirportsList"
                        @update:searchTerm="getAirportsList"
                        class="w-full"
                        :ui="{value: hasRoute ? 'text-white' : 'text-black'}"
                    >
                        <template #item="{ item }">
                            <u-icon :name="planeIcon()"/>
                            <div class="flex flex-col">
                                <span class="truncate">{{ item.city.name_fa }}</span>
                                <div class="flex gap-2">
                                    <span class="text-xs text-gray-400 font-light">{{ item.name_fa }}</span>
                                    <span class="text-xs text-gray-400 font-light">{{ item.iata_code }}</span>
                                </div>
                            </div>
                        </template>
                        <template #empty="{searchTerm}">
                            <div
                                v-if="airportLoading"
                                class="w-full flex flex-col gap-2"

                            >
                                <u-skeleton
                                    v-for="i in 5"
                                    :key="i"
                                    class="h-7 w-full"
                                />
                            </div>
                            <span v-else class="text-xs text-red-500 tracking-tight"><q
                                class="text-red-600">{{ searchTerm }}</q> پیدا نشد</span>
                        </template>
                    </u-select-menu>
                    <template #error="{error}">{{ error }}</template>
                </u-form-field>
                <u-form-field
                    name="destination"
                    :ui="{root: hasRoute ? 'w-full bg-transparent border border-white rounded-r-2xl rounded-l-lg' : 'w-full bg-transparent border border-primary-900 rounded-r-2xl rounded-l-lg', error: 'absolute text-xs'}"
                >
                    <u-select-menu
                        v-model="state.destination"
                        :filter-fields="['name_en', 'name_fa', 'iata_code', 'city.name_en', 'city.name_fa']"
                        :items="airports"
                        :loading="airportLoading"
                        :search-input="{placeholder: 'جستجوی مقصد'}"
                        placeholder="مقصد"
                        label-key="city.name_fa"
                        highlight
                        color="gray"
                        size="xl"
                        variant="none"
                        trailing
                        :leading-icon="loadingIcon()"
                        by="iata_code"
                        @focus="getAirportsList"
                        @update:searchTerm="getAirportsList"
                        class="w-full"
                        :ui="{value: hasRoute ? 'text-white' : 'text-black'}"
                    >
                        <template #item="{ item }">
                            <u-icon :name="planeIcon()"/>
                            <div class="flex flex-col">
                                <span class="truncate">{{ item.city.name_fa }}</span>
                                <div class="flex gap-2">
                                    <span class="text-xs text-gray-400 font-light">{{ item.name_fa }}</span>
                                    <span class="text-xs text-gray-400 font-light">{{ item.iata_code }}</span>
                                </div>
                            </div>
                        </template>
                        <template #empty="{searchTerm}">
                            <div
                                v-if="airportLoading"
                                class="w-full flex flex-col gap-2"

                            >
                                <u-skeleton
                                    v-for="i in 5"
                                    :key="i"
                                    class="h-7 w-full"
                                />
                            </div>
                            <span v-else class="text-xs text-red-500 tracking-tight"><q
                                class="text-red-600">{{ searchTerm }}</q> پیدا نشد</span>
                        </template>
                    </u-select-menu>
                </u-form-field>
            </div>
            <div
                class="w-full flex gap-4 relative"
                :class="!noPath ? 'col-span-2' : 'col-span-3 col-start-3'"
            >
                <u-form-field
                    name="start_date"
                    :ui="{root: hasRoute ? 'w-full bg-transparent border border-white rounded-lg' : 'w-full bg-transparent border border-primary-900 rounded-lg', error: 'absolute text-xs'}"
                >
                    <u-input
                        v-model="dateInput"
                        type="text"
                        @focusin="showDatePiker = true"
                        :placeholder="state.tripType.name === 'trip' ? 'تاریخ' : 'تاریخ ها'"
                        color="gray"
                        variant="none"
                        :leading-icon="calendarIcon()"
                        :ui="{base: `${hasRoute ? 'text-white' : ''}` , root: `${hasRoute ? 'text-white' : ''} w-full py-1 text-center  border-0 outline-none ring-0 focus:border-0 focus:outline-none focus:ring-0 bg-transparent`}"
                        autocomplete="false"
                        readonly
                    />
                    <div v-if="showDatePiker" class="absolute left-1/2 -translate-x-1/2 mt-1 w-[700px] z-20">
                        <lazy-picker-custom-date-picker-component
                            @handle-date="handleDate"
                            :dates="state.date"
                            :selection-type="state.tripType.name === 'roundTrip' ? 'range' : 'single'"
                        />
                    </div>

                    <template #error="{ error }">
                        {{ error }}
                    </template>
                </u-form-field>
            </div>
            <div class="w-full flex gap-4 col-span-2 relative">
                <u-form-field
                    name="passengers"
                    :ui="{root: hasRoute ? 'w-full bg-transparent border border-white rounded-lg' : 'w-full bg-transparent border border-primary-900 rounded-lg', error: 'absolute text-xs'}"
                >
                    <u-input
                        v-model="passengerText"
                        type="text"
                        @focusin="showPassengersOptions = true"
                        placeholder="مسافران"
                        color="gray"
                        variant="none"
                        leading-icon="i-mdi-seat-passenger"
                        :ui="{base: `${hasRoute ? 'text-white' : ''}` ,root: `${hasRoute ? 'text-white' : ''} w-full py-1 text-center  border-0 outline-none ring-0 focus:border-0 focus:outline-none focus:ring-0 bg-transparent`}"
                        autocomplete="false"
                        readonly
                    />

                    <lazy-flight-search-passenger-information-component
                        :passengers="state.passengers"
                        :open="showPassengersOptions"
                        @close="(val: boolean) => showPassengersOptions = val"
                        @handle-passenger="handlePassenger"
                    />

                    <template #error="{ error }">
                        {{ error }}
                    </template>
                </u-form-field>
            </div>

            <div class="w-full col-span-1 flex items-center justify-center">
                <u-button
                    type="submit"
                    label="جستجو"
                    :color="hasRoute ? 'neutral' : 'primary'"
                    :variant="hasRoute ? 'outline' : 'solid'"
                    :ui="{base: `${hasRoute ? 'text-black' : '' } w-full flex justify-center py-3 rounded-full`}"
                />
            </div>
        </u-form>
    </div>

    <lazy-common-bg-black-cover-component
        v-if="showPassengersOptions || showDatePiker"
        bg-color="bg-transparent"
        @close="handleOut"
    />
</template>
